.navbar-home-container {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  background-color: var(--dl-color-theme-neutral-light);
}
.navbar-home-navbar-interactive {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.navbar-home-navlink {
  display: contents;
}
.navbar-home-image1 {
  height: 3rem;
  text-decoration: none;
}
.navbar-home-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.navbar-home-links {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-end;
  text-decoration: none;
}
.navbar-home-icon-group {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-self: center;
  justify-content: flex-start;
}
.navbar-home-link {
  display: contents;
}
.navbar-home-icon {
  text-decoration: none;
}
.navbar-home-link1 {
  display: contents;
}
.navbar-home-icon02 {
  text-decoration: none;
}
.navbar-home-link2 {
  display: contents;
}
.navbar-home-icon04 {
  text-decoration: none;
}
.navbar-home-text {
  fill: var(--dl-color-scheme-primary);
  color: var(--dl-color-scheme-primary);
  font-size: 24px;
  align-self: center;
  font-style: normal;
  font-weight: 700;
  margin-right: var(--dl-space-space-threeunits);
  text-decoration: none;
}
.navbar-home-buttons {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.navbar-home-action1 {
  display: flex;
  border-color: var(--dl-color-scheme-primary);
  flex-direction: row;
  text-decoration: none;
  background-color: var(--dl-color-scheme-primary);
}
.navbar-home-text1 {
  font-family: "Arial";
}
.navbar-home-burger-menu {
  display: none;
}
.navbar-home-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navbar-home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-twounits);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-theme-neutral-light);
}
.navbar-home-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navbar-home-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.navbar-home-navlink1 {
  display: contents;
}
.navbar-home-logo {
  height: 3rem;
  text-decoration: none;
}
.navbar-home-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar-home-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navbar-home-text2 {
  text-decoration: none;
}
.navbar-home-action11 {
  text-decoration: none;
}
.navbar-home-icon-group1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
}
.navbar-home-link3 {
  display: contents;
}
.navbar-home-icon10 {
  text-decoration: none;
}
.navbar-home-link4 {
  display: contents;
}
.navbar-home-icon12 {
  text-decoration: none;
}
.navbar-home-link5 {
  display: contents;
}
.navbar-home-icon14 {
  text-decoration: none;
}


@media(max-width: 991px) {
  .navbar-home-links {
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
  .navbar-home-icon-group {
    align-self: flex-end;
    margin-right: var(--dl-space-space-twounits);
  }
  .navbar-home-text {
    fill: var(--dl-color-scheme-primary);
    color: var(--dl-color-scheme-primary);
    font-size: 24px;
    align-self: center;
    font-style: normal;
    font-weight: 700;
    margin-right: var(--dl-space-space-threeunits);
    text-decoration: none;
  }
  .navbar-home-action1 {
    border-color: var(--dl-color-scheme-primary);
    background-color: var(--dl-color-scheme-primary);
  }
  .navbar-home-text1 {
    font-family: Arial;
  }
}
@media(max-width: 767px) {
  .navbar-home-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .navbar-home-desktop-menu {
    display: none;
  }
  .navbar-home-text {
    fill: var(--dl-color-scheme-primary);
    color: var(--dl-color-scheme-primary);
    align-self: center;
    font-family: Arial;
    font-weight: bold;
    margin-bottom: var(--dl-space-space-unit);
  }
  .navbar-home-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navbar-home-mobile-menu {
    height: 22vh;
  }
  .navbar-home-top {
    margin-bottom: 0px;
  }
  .navbar-home-text2 {
    fill: var(--dl-color-scheme-primary);
    color: var(--dl-color-scheme-primary);
    align-self: center;
    font-family: Arial;
    font-weight: bold;
    margin-bottom: var(--dl-space-space-unit);
  }
  .navbar-home-action11 {
    align-self: center;
    padding-top: var(--dl-space-space-halfunit);
    border-color: var(--dl-color-scheme-primary);
    background-color: var(--dl-color-scheme-primary);
  }
}
@media(max-width: 479px) {
  .navbar-home-navbar-interactive {
    padding: var(--dl-space-space-unit);
    padding-right: 16px;
  }
  .navbar-home-icon-group {
    align-self: flex-start;
    margin-top: var(--dl-space-space-oneandhalfunits);
  }
  .navbar-home-text {
    fill: var(--dl-color-scheme-primary);
    color: var(--dl-color-scheme-primary);
    align-self: center;
    font-family: Arial;
    font-weight: bold;
    margin-bottom: 0px;
  }
  .navbar-home-mobile-menu {
    height: auto;
    padding: var(--dl-space-space-unit);
  }
  .navbar-home-nav {
    height: 181px;
  }
  .navbar-home-top {
    margin-bottom: 0px;
  }
  .navbar-home-text2 {
    fill: var(--dl-color-scheme-primary);
    color: var(--dl-color-scheme-primary);
    align-self: center;
    font-family: Arial;
    font-weight: bold;
    margin-bottom: 0px;
  }
  .navbar-home-action11 {
    align-self: center;
    margin-top: 20px;
    align-items: center;
    border-color: var(--dl-color-scheme-primary);
    flex-direction: row;
    justify-content: center;
    background-color: var(--dl-color-scheme-primary);
  }
  .navbar-home-icon-group1 {
    align-self: flex-start;
    margin-top: var(--dl-space-space-oneandhalfunits);
  }
}
