.navbar-about-container {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  background-color: var(--dl-color-theme-neutral-light);
}
.navbar-about-navbar-interactive {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.navbar-about-navlink {
  display: contents;
}
.navbar-about-image1 {
  height: 3rem;
  text-decoration: none;
}
.navbar-about-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.navbar-about-links {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-end;
  text-decoration: none;
}
.navbar-about-icon-group {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-self: center;
  justify-content: flex-start;
}
.navbar-about-link {
  display: contents;
}
.navbar-about-icon {
  text-decoration: none;
}
.navbar-about-link1 {
  display: contents;
}
.navbar-about-icon02 {
  text-decoration: none;
}
.navbar-about-link2 {
  display: contents;
}
.navbar-about-icon04 {
  text-decoration: none;
}
.navbar-about-buttons {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.navbar-about-action1 {
  display: flex;
  border-color: var(--dl-color-scheme-primary);
  flex-direction: row;
  text-decoration: none;
  background-color: var(--dl-color-scheme-primary);
}
.navbar-about-text {
  font-family: "Arial";
}
.navbar-about-burger-menu {
  display: none;
}
.navbar-about-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navbar-about-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-twounits);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-theme-neutral-light);
}
.navbar-about-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navbar-about-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.navbar-about-navlink1 {
  display: contents;
}
.navbar-about-logo {
  height: 3rem;
  text-decoration: none;
}
.navbar-about-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar-about-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navbar-about-action11 {
  text-decoration: none;
}
.navbar-about-icon-group1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
}
.navbar-about-link3 {
  display: contents;
}
.navbar-about-icon10 {
  text-decoration: none;
}
.navbar-about-link4 {
  display: contents;
}
.navbar-about-icon12 {
  text-decoration: none;
}
.navbar-about-link5 {
  display: contents;
}
.navbar-about-icon14 {
  text-decoration: none;
}

@media(max-width: 991px) {
  .navbar-about-links {
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
  .navbar-about-icon-group {
    align-self: flex-end;
    margin-right: var(--dl-space-space-twounits);
  }
  .navbar-about-action1 {
    border-color: var(--dl-color-scheme-primary);
    background-color: var(--dl-color-scheme-primary);
  }
  .navbar-about-text {
    font-family: Arial;
  }
}
@media(max-width: 767px) {
  .navbar-about-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .navbar-about-desktop-menu {
    display: none;
  }
  .navbar-about-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navbar-about-mobile-menu {
    height: 18vh;
  }
  .navbar-about-top {
    margin-bottom: 0px;
  }
  .navbar-about-action11 {
    align-self: center;
    padding-top: var(--dl-space-space-halfunit);
    border-color: var(--dl-color-scheme-primary);
    background-color: var(--dl-color-scheme-primary);
  }
}
@media(max-width: 479px) {
  .navbar-about-navbar-interactive {
    padding: var(--dl-space-space-unit);
    padding-right: 16px;
  }
  .navbar-about-icon-group {
    align-self: flex-start;
    margin-top: var(--dl-space-space-oneandhalfunits);
  }
  .navbar-about-mobile-menu {
    height: 18vh;
    padding: var(--dl-space-space-unit);
  }
  .navbar-about-nav {
    height: 181px;
  }
  .navbar-about-top {
    margin-bottom: 0px;
  }
  .navbar-about-action11 {
    align-self: center;
    margin-top: 20px;
    align-items: center;
    border-color: var(--dl-color-scheme-primary);
    flex-direction: row;
    justify-content: center;
    background-color: var(--dl-color-scheme-primary);
  }
  .navbar-about-icon-group1 {
    align-self: flex-start;
    margin-top: var(--dl-space-space-oneandhalfunits);
  }
}
