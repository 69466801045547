.about-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.about-container01 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.about-navbar {
  display: none;
}
.about-logo {
  width: 420px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.about-navlink {
  display: contents;
}
.about-image {
  width: 100px;
  object-fit: cover;
  text-decoration: none;
}
.about-navlink1 {
  top: 31px;
  color: rgb(0, 0, 230);
  right: 45px;
  height: auto;
  position: absolute;
  font-size: 32px;
  font-style: normal;
  text-align: center;
  font-family: Roboto;
  font-weight: 900;
  text-decoration: none;
}
.about-links {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.about-text {
  color: var(--dl-color-scheme-green80);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.about-link {
  text-decoration: none;
}
.about-burger-menu {
  fill: #105749;
  width: 24px;
  height: 24px;
}
.about-hero {
  height: 859px;
  display: none;
  margin-bottom: 21px;
  padding-bottom: 67px;
  background-color: #0000e6;
}
.about-max-width1 {
  align-items: center;
}
.about-content {
  flex: 0 0 auto;
  width: 507px;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.about-subtitle {
  color: var(--dl-color-scheme-orange100);
  font-family: Roboto;
  margin-bottom: var(--dl-space-space-unit);
}
.about-title {
  color: var(--dl-color-scheme-white);
  font-size: 52px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: var(--dl-space-space-twounits);
}
.about-description {
  color: var(--dl-color-scheme-white);
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-threeunits);
}
.about-container02 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.about-link1 {
  text-decoration: none;
}
.about-image1 {
  position: relative;
  padding-bottom: var(--dl-space-space-eightunits);
}
.about-hero-image {
  flex: 1;
  width: 550px;
  height: 732px;
  display: flex;
  align-items: center;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: dashed;
  border-width: 0px;
  border-radius: 48px;
  background-size: cover;
  justify-content: center;
  background-image: url("https://play.teleporthq.io/static/svg/placeholders/no-image.svg");
  background-position: center;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.about-section {
  height: 726px;
  padding-top: var(--dl-space-space-unit);
  padding-bottom: 4px;
}
.about-container03 {
  width: 1186px;
  height: 621px;
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-scheme-green100);
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-size: cover;
  justify-content: flex-start;
  background-image: url("/ot3lrpbu3-700h.png");
  border-bottom-left-radius: var(--dl-radius-radius-radius16);
  border-bottom-right-radius: var(--dl-radius-radius-round);
}
.about-text01 {
  color: rgb(255, 255, 255);
  width: 121px;
  font-size: 42px;
  align-self: center;
  font-style: normal;
  margin-top: 16px;
  text-align: center;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-twounits);
}
.about-container04 {
  width: 488px;
  height: 530px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.about-text04 {
  color: rgb(255, 255, 255);
  width: auto;
  font-size: 16px;
  align-self: flex-start;
  text-align: left;
  line-height: 1.5;
  margin-left: 8px;
  margin-bottom: var(--dl-space-space-threeunits);
  background-color: rgba(6, 6, 6, 0.56);
}
.about-section1 {
  height: 823px;
  display: none;
  padding-top: 67px;
  background-color: rgba(255, 199, 139, 0.14);
}
.about-max-width2 {
  align-items: stretch;
}
.about-content1 {
  width: 50%;
  display: flex;
  margin-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.about-text21 {
  color: var(--dl-color-scheme-green80);
  margin-bottom: var(--dl-space-space-unit);
}
.about-text22 {
  font-size: 42px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-twounits);
}
.about-text23 {
  color: var(--dl-color-scheme-orange100);
}
.about-text25 {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-threeunits);
}
.about-container05 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: row;
}
.about-image2 {
  width: 577px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius4);
  border-top-left-radius: var(--dl-radius-radius-round);
  border-top-right-radius: var(--dl-radius-radius-round);
  border-bottom-left-radius: var(--dl-radius-radius-round);
  border-bottom-right-radius: var(--dl-radius-radius-round);
}
.about-section2 {
  display: none;
  background-color: var(--dl-color-scheme-green100);
}
.about-max-width3 {
  align-items: stretch;
  padding-left: var(--dl-space-space-halfunit);
}
.about-image3 {
  width: 646px;
  height: 567px;
  align-self: center;
}
.about-hero-image1 {
  width: 100%;
  height: 539px;
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 48px;
  background-size: contain;
  justify-content: center;
  text-decoration: none;
  background-image: url("/img_2810-1200w.jpg");
  background-position: center;
}
.about-container06 {
  flex: 0 0 auto;
  width: 238px;
  height: 172px;
  display: flex;
  align-items: center;
  border-radius: 48px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-green100);
}
.about-text26 {
  color: var(--dl-color-scheme-white);
  width: 311px;
  height: 115px;
  font-size: 42px;
  align-self: center;
  font-style: normal;
  text-align: center;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.35;
  border-radius: var(--dl-radius-radius-radius4);
  border-top-left-radius: var(--dl-radius-radius-round);
  border-top-right-radius: var(--dl-radius-radius-round);
  border-bottom-left-radius: var(--dl-radius-radius-round);
  border-bottom-right-radius: var(--dl-radius-radius-round);
}
.about-text27 {
  text-transform: capitalize;
}
.about-text28 {
  text-transform: capitalize;
}
.about-text29 {
  text-transform: capitalize;
}
.about-content2 {
  width: 40%;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.about-text31 {
  color: var(--dl-color-scheme-white);
  margin-bottom: var(--dl-space-space-unit);
}
.about-text32 {
  color: var(--dl-color-scheme-white);
  font-size: 42px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.35;
  margin-bottom: var(--dl-space-space-twounits);
}
.about-step {
  display: flex;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.about-number {
  flex: 0 0 auto;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius16);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-yellow20);
}
.about-text35 {
  font-size: 28px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
}
.about-container07 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.about-title1 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-halfunit);
}
.about-text36 {
  color: var(--dl-color-scheme-white);
  line-height: 1.5;
}
.about-step1 {
  display: flex;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.about-number1 {
  flex: 0 0 auto;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius16);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-yellow20);
}
.about-text37 {
  font-size: 28px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
}
.about-container08 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.about-title2 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-halfunit);
}
.about-text38 {
  color: var(--dl-color-scheme-white);
  line-height: 1.5;
}
.about-step2 {
  display: flex;
  flex-direction: row;
}
.about-number2 {
  flex: 0 0 auto;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius16);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-yellow20);
}
.about-text39 {
  font-size: 28px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
}
.about-container09 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.about-title3 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-halfunit);
}
.about-text40 {
  color: var(--dl-color-scheme-white);
  line-height: 1.5;
}
.about-section3 {
  display: none;
}
.about-max-width4 {
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.about-text41 {
  color: var(--dl-color-scheme-green80);
  margin-bottom: var(--dl-space-space-halfunit);
}
.about-text42 {
  font-size: 42px;
  font-style: normal;
  text-align: center;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-threeunits);
}
.about-cards-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.about-section4 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.about-max-width5 {
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.about-faq {
  flex: 0 0 auto;
  width: 100%;
  display: none;
  align-items: center;
  margin-bottom: var(--dl-space-space-eightunits);
  flex-direction: row;
  justify-content: space-between;
}
.about-questions {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.about-text43 {
  color: var(--dl-color-scheme-green80);
  margin-bottom: var(--dl-space-space-unit);
}
.about-text44 {
  font-size: 42px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-twounits);
}
.about-text45 {
  font-family: Urbanist;
}
.about-text47 {
  color: var(--dl-color-scheme-orange100);
  font-family: Urbanist;
}
.about-question {
  align-self: flex-start;
  align-items: flex-start;
}
.about-trigger {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.about-text48 {
  font-size: 20px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-halfunit);
}
.about-container10 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.about-text49 {
  margin-top: 8px;
  text-transform: capitalize;
}
.about-answer {
  border: 2px dashed rgba(120, 120, 120, 0.4);
}
.about-trigger1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.about-text50 {
  font-size: 20px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-halfunit);
}
.about-text51 {
  margin-top: 8px;
}
.about-question2 {
  display: none;
}
.about-trigger2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.about-text52 {
  font-size: 20px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.5;
}
.about-icon2 {
  width: 12px;
  height: 12px;
}
.about-text53 {
  margin-top: 8px;
}
.about-question3 {
  align-self: flex-start;
  align-items: flex-start;
}
.about-trigger3 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  justify-content: space-between;
}
.about-text54 {
  font-size: 20px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.5;
}
.about-text55 {
  margin-top: var(--dl-space-space-unit);
}
.about-image4 {
  width: 722px;
  height: 540px;
  object-fit: cover;
  margin-right: -200px;
  border-radius: var(--dl-radius-radius-radius16);
  border-top-left-radius: Radius46;
  border-bottom-left-radius: var(--dl-radius-radius-radius16);
}
.about-banner {
  width: 100%;
  height: 695px;
  display: flex;
  padding: var(--dl-space-space-sixunits);
  z-index: 100;
  flex-direction: column;
  background-size: cover;
  background-image: url("/b808ea7dedf%20(1)-1200w.png");
}
@media(max-width: 991px) {
  .about-navbar {
    display: none;
  }
  .about-content {
    width: 50%;
    margin-right: var(--dl-space-space-unit);
  }
  .about-image1 {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .about-hero-image {
    margin-bottom: 0px;
  }
  .about-container03 {
    width: 991px;
    margin-right: 0px;
  }
  .about-container04 {
    width: 388px;
    height: 509px;
  }
  .about-text04 {
    margin-bottom: 0px;
  }
  .about-max-width2 {
    flex-direction: column;
  }
  .about-content1 {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .about-max-width3 {
    flex-direction: column;
  }
  .about-image3 {
    width: 100%;
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .about-hero-image1 {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 0px;
  }
  .about-content2 {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  .about-max-width4 {
    flex-direction: column;
  }
  .about-max-width5 {
    flex-direction: column;
  }
  .about-questions {
    width: 60%;
  }
  .about-image4 {
    margin-right: -100px;
  }
}
@media(max-width: 767px) {
  .about-navbar {
    display: none;
  }
  .about-links {
    display: none;
  }
  .about-icon {
    fill: var(--dl-color-scheme-green100);
  }
  .about-max-width1 {
    flex-direction: column-reverse;
  }
  .about-content {
    width: 100%;
    margin-right: 0px;
  }
  .about-hero-image {
    margin-right: 0px;
  }
  .about-container03 {
    width: 767px;
  }
  .about-container04 {
    width: 100%;
    height: 362px;
  }
  .about-text04 {
    background-color: rgba(6, 6, 6, 0.88);
  }
  .about-content1 {
    width: 100%;
    margin-right: 0px;
  }
  .about-content2 {
    width: 100%;
    margin-right: 0px;
  }
  .about-cards-container {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-wrap: wrap;
    justify-content: center;
  }
  .about-faq {
    margin-bottom: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .about-questions {
    width: 100%;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .about-image4 {
    width: 100%;
    margin-right: 0px;
  }
  .about-banner {
    height: 518px;
    padding: var(--dl-space-space-twounits);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
@media(max-width: 479px) {
  .about-navbar {
    display: none;
  }
  .about-container03 {
    width: 432px;
  }
  .about-container04 {
    width: 421px;
    height: 494px;
    margin-top: 100px;
  }
  .about-banner {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
