.card-card {
  flex: 0 0 auto;
  width: 32%;
  display: flex;
  padding: 20px;
  max-width: 450px;
  min-height: 480px;
  align-items: center;
  border-radius: 36px;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-end;
  background-color: #D9D9D9;
  background-position: center;
}
.card-info {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 20px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-scheme-white);
}
.card-text {
  fill: var(--dl-color-scheme-primary);
  color: var(--dl-color-scheme-primary);
  font-size: 20px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-halfunit);
}
.card-text1 {
  fill: var(--dl-color-scheme-primary);
  color: var(--dl-color-scheme-primary);
  line-height: 1.5;
}
.card-root-class-name {
  align-self: flex-start;
  background-image: url('https://images.unsplash.com/photo-1507679799987-c73779587ccf?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDU0fHxidXNpbmVzc3xlbnwwfHx8fDE2ODIxNDA5NTU&ixlib=rb-4.0.3&q=100&w=600');
}
.card-root-class-name1 {
  background-size: cover;
  background-image: url('https://images.unsplash.com/photo-1495474472287-4d71bcdd2085?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDQ3fHxjb21tdW5pdHl8ZW58MHx8fHwxNjgyMTQxNDEw&ixlib=rb-4.0.3&q=100&w=600');
}
.card-root-class-name2 {
  background-size: cover;
  background-image: url('https://images.unsplash.com/photo-1511632765486-a01980e01a18?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDEzfHxmcmllbmRzfGVufDB8fHx8MTY4MjE0MTE0MQ&ixlib=rb-4.0.3&q=100&w=600');
}
.card-root-class-name3 {
  align-self: flex-start;
  background-image: url('https://images.unsplash.com/photo-1507679799987-c73779587ccf?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDU0fHxidXNpbmVzc3xlbnwwfHx8fDE2ODIxNDA5NTU&ixlib=rb-4.0.3&q=100&w=600');
}
.card-root-class-name4 {
  background-size: cover;
  background-image: url('https://images.unsplash.com/photo-1511632765486-a01980e01a18?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDEzfHxmcmllbmRzfGVufDB8fHx8MTY4MjE0MTE0MQ&ixlib=rb-4.0.3&q=100&w=600');
}
.card-root-class-name5 {
  background-size: cover;
  background-image: url('https://images.unsplash.com/photo-1495474472287-4d71bcdd2085?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDQ3fHxjb21tdW5pdHl8ZW58MHx8fHwxNjgyMTQxNDEw&ixlib=rb-4.0.3&q=100&w=600');
}
.card-root-class-name6 {
  align-self: flex-start;
  background-image: url('https://images.unsplash.com/photo-1507679799987-c73779587ccf?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDU0fHxidXNpbmVzc3xlbnwwfHx8fDE2ODIxNDA5NTU&ixlib=rb-4.0.3&q=100&w=600');
}
.card-root-class-name7 {
  background-size: cover;
  background-image: url('https://images.unsplash.com/photo-1511632765486-a01980e01a18?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDEzfHxmcmllbmRzfGVufDB8fHx8MTY4MjE0MTE0MQ&ixlib=rb-4.0.3&q=100&w=600');
}
.card-root-class-name8 {
  background-size: cover;
  background-image: url('https://images.unsplash.com/photo-1495474472287-4d71bcdd2085?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDQ3fHxjb21tdW5pdHl8ZW58MHx8fHwxNjgyMTQxNDEw&ixlib=rb-4.0.3&q=100&w=600');
}
@media(max-width: 767px) {
  .card-card {
    width: 100%;
  }
}
