.home-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-hero {
  height: 859px;
  margin-bottom: 21px;
  padding-bottom: 67px;
  background-color: var(--dl-color-scheme-primary);
}
.home-max-width {
  align-items: center;
}
.home-content {
  flex: 0 0 auto;
  width: 507px;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.home-subtitle {
  color: var(--dl-color-scheme-orange100);
  font-family: Roboto;
  margin-bottom: var(--dl-space-space-unit);
}
.home-title {
  color: var(--dl-color-scheme-white);
  font-size: 52px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-description {
  color: var(--dl-color-scheme-white);
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-link {
  fill: var(--dl-color-scheme-primary);
  color: var(--dl-color-scheme-primary);
  border-color: var(--dl-color-scheme-primary);
  text-decoration: none;
}
.home-image {
  position: relative;
  padding-bottom: var(--dl-space-space-eightunits);
}
.home-hero-image {
  flex: 1;
  width: 550px;
  height: 732px;
  display: flex;
  align-items: center;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: dashed;
  border-width: 0px;
  border-radius: 48px;
  background-size: cover;
  justify-content: center;
  background-image: url("/smartmockups_lxy1yelt-800h.jpg");
  background-position: center;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.home-section {
  height: 726px;
  padding-top: var(--dl-space-space-unit);
  padding-bottom: 4px;
}
.home-container2 {
  width: 1186px;
  height: 621px;
  display: flex;
  position: relative;
  align-items: center;
  border-color: var(--dl-color-scheme-green100);
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius4);
  background-size: cover;
  justify-content: flex-start;
  background-image: url("/ot3lrpbu3-700h.png");
  background-position: center;
  border-bottom-left-radius: var(--dl-radius-radius-radius16);
  border-bottom-right-radius: var(--dl-radius-radius-round);
}
.home-content1 {
  left: 100px;
  width: 339px;
  bottom: 80px;
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
}
.home-text {
  color: #fdfdfd;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text01 {
  color: rgb(255, 255, 255);
  width: 425px;
  font-size: 42px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-text04 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-section1 {
  height: 823px;
  padding-top: 67px;
  background-color: rgba(255, 199, 139, 0.14);
}
.home-max-width1 {
  align-items: stretch;
}
.home-content2 {
  width: 50%;
  display: flex;
  margin-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.home-text05 {
  fill: var(--dl-color-scheme-primary);
  color: var(--dl-color-scheme-primary);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text06 {
  font-size: 42px;
  font-style: normal;
  font-family: "Urbanist";
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-text07 {
  color: var(--dl-color-scheme-orange100);
}
.home-text09 {
  color: var(--dl-color-scheme-primary);
}
.home-text10 {
  color: var(--dl-color-scheme-primary);
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-container3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: row;
}
.home-button {
  fill: var(--dl-color-scheme-primary);
  color: var(--dl-color-scheme-primary);
  border-color: var(--dl-color-scheme-primary);
}
.home-image1 {
  width: 577px;
  height: 577px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius4);
  border-top-left-radius: var(--dl-radius-radius-round);
  border-top-right-radius: var(--dl-radius-radius-round);
  border-bottom-left-radius: var(--dl-radius-radius-round);
  border-bottom-right-radius: var(--dl-radius-radius-round);
}
.home-section2 {
  background-color: var(--dl-color-scheme-primary);
}
.home-max-width2 {
  align-items: stretch;
  padding-left: var(--dl-space-space-halfunit);
}
.home-image2 {
  width: 646px;
  height: 567px;
  align-self: center;
}
.home-hero-image1 {
  width: 100%;
  height: 539px;
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 48px;
  background-size: cover;
  justify-content: center;
  text-decoration: none;
  background-image: url("/img_2724-1200w.jpg");
  background-position: top;
}
.home-container4 {
  flex: 0 0 auto;
  width: 238px;
  height: 172px;
  display: flex;
  align-items: center;
  border-radius: 48px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-primary);
}
.home-text11 {
  color: var(--dl-color-scheme-white);
  width: 311px;
  height: 115px;
  font-size: 42px;
  align-self: center;
  font-style: normal;
  text-align: center;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.35;
  border-radius: var(--dl-radius-radius-radius4);
  border-top-left-radius: var(--dl-radius-radius-round);
  border-top-right-radius: var(--dl-radius-radius-round);
  border-bottom-left-radius: var(--dl-radius-radius-round);
  border-bottom-right-radius: var(--dl-radius-radius-round);
}
.home-text12 {
  text-transform: capitalize;
}
.home-text13 {
  text-transform: capitalize;
}
.home-text14 {
  text-transform: capitalize;
}
.home-content3 {
  width: 40%;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.home-text16 {
  color: var(--dl-color-scheme-white);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text17 {
  color: var(--dl-color-scheme-white);
  font-size: 42px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.35;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-step {
  display: flex;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-number {
  flex: 0 0 auto;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius16);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-yellow20);
}
.home-text20 {
  fill: var(--dl-color-scheme-primary);
  color: var(--dl-color-scheme-primary);
  font-size: 28px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
}
.home-container5 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-title1 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  font-style: normal;
  font-family: "Urbanist";
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text21 {
  color: var(--dl-color-scheme-white);
  line-height: 1.5;
}
.home-step1 {
  display: flex;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-number1 {
  flex: 0 0 auto;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius16);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-yellow20);
}
.home-text22 {
  fill: var(--dl-color-scheme-primary);
  color: var(--dl-color-scheme-primary);
  font-size: 28px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
}
.home-container6 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-title2 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text23 {
  color: var(--dl-color-scheme-white);
  line-height: 1.5;
}
.home-step2 {
  display: flex;
  flex-direction: row;
}
.home-number2 {
  flex: 0 0 auto;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius16);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-yellow20);
}
.home-text24 {
  fill: var(--dl-color-scheme-primary);
  color: var(--dl-color-scheme-primary);
  font-size: 28px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
}
.home-container7 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-title3 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text25 {
  color: var(--dl-color-scheme-white);
  line-height: 1.5;
}
.home-max-width3 {
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text26 {
  fill: var(--dl-color-scheme-primary);
  color: var(--dl-color-scheme-primary);
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text27 {
  fill: var(--dl-color-scheme-primary);
  color: var(--dl-color-scheme-primary);
  font-size: 42px;
  font-style: normal;
  text-align: center;
  font-family: "Urbanist";
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-cards-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-section4 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.home-max-width4 {
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-faq {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-eightunits);
  flex-direction: row;
  justify-content: space-between;
}
.home-questions {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text28 {
  fill: var(--dl-color-scheme-primary);
  color: var(--dl-color-scheme-primary);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text29 {
  font-size: 42px;
  font-style: normal;
  font-family: "Urbanist";
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-text30 {
  color: var(--dl-color-scheme-primary);
  font-family: "Urbanist";
}
.home-text31 {
  color: var(--dl-color-scheme-primary);
}
.home-text32 {
  color: var(--dl-color-scheme-orange100);
  font-family: "Urbanist";
}
.home-question {
  align-self: flex-start;
  align-items: flex-start;
}
.home-trigger {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-text33 {
  fill: var(--dl-color-scheme-primary);
  color: var(--dl-color-scheme-primary);
  font-size: 20px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-container8 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.home-text34 {
  fill: var(--dl-color-scheme-primary);
  color: var(--dl-color-scheme-primary);
  margin-top: 8px;
  text-transform: capitalize;
}
.home-trigger1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-text35 {
  fill: var(--dl-color-scheme-primary);
  color: var(--dl-color-scheme-primary);
  font-size: 20px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text36 {
  fill: var(--dl-color-scheme-primary);
  color: var(--dl-color-scheme-primary);
  margin-top: 8px;
}
.home-question2 {
  display: none;
}
.home-trigger2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-text37 {
  font-size: 20px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.5;
}
.home-icon {
  width: 12px;
  height: 12px;
}
.home-text38 {
  margin-top: 8px;
}
.home-question3 {
  align-self: flex-start;
  align-items: flex-start;
}
.home-trigger3 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  justify-content: space-between;
}
.home-text39 {
  fill: var(--dl-color-scheme-primary);
  color: var(--dl-color-scheme-primary);
  font-size: 20px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.5;
}
.home-text40 {
  fill: var(--dl-color-scheme-primary);
  color: var(--dl-color-scheme-primary);
  margin-top: var(--dl-space-space-unit);
}
.home-image3 {
  width: 722px;
  height: 540px;
  object-fit: cover;
  margin-right: -200px;
  border-radius: var(--dl-radius-radius-radius16);
  border-top-left-radius: Radius46;
  border-bottom-left-radius: var(--dl-radius-radius-radius16);
}
.home-banner {
  width: 100%;
  height: 695px;
  display: flex;
  padding: var(--dl-space-space-sixunits);
  z-index: 100;
  border-radius: 48px;
  flex-direction: column;
  background-size: cover;
  background-image: url("/b808ea7dedf%20(1)-1200w.png");
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
@media(max-width: 991px) {
  .home-content {
    width: 50%;
    margin-right: var(--dl-space-space-unit);
  }
  .home-image {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-hero-image {
    margin-bottom: 0px;
  }
  .home-content1 {
    width: 369px;
    margin: 0px;
  }
  .home-section1 {
    padding-top: var(--dl-space-space-oneandhalfunits);
  }
  .home-max-width1 {
    flex-direction: column;
  }
  .home-content2 {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-section2 {
    align-items: center;
  }
  .home-max-width2 {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .home-image2 {
    width: 621px;
    padding-left: 0px;
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-hero-image1 {
    width: 613px;
    margin-left: 0px;
    margin-bottom: 0px;
  }
  .home-content3 {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  .home-max-width3 {
    flex-direction: column;
  }
  .home-max-width4 {
    flex-direction: column;
  }
  .home-questions {
    width: 60%;
  }
  .home-image3 {
    margin-right: -100px;
  }
  .home-banner {
    height: 625px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
@media(max-width: 767px) {
  .home-max-width {
    flex-direction: column-reverse;
  }
  .home-content {
    width: 100%;
    margin-right: 0px;
  }
  .home-hero-image {
    margin-right: 0px;
  }
  .home-content1 {
    width: 100%;
    margin-right: 0px;
  }
  .home-content2 {
    width: 100%;
    margin-right: 0px;
  }
  .home-hero-image1 {
    background-image: url("/img_2724-1200w.jpg");
  }
  .home-content3 {
    width: 100%;
    margin-right: 0px;
  }
  .home-cards-container {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-wrap: wrap;
    justify-content: center;
  }
  .home-faq {
    margin-bottom: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .home-questions {
    width: 100%;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-image3 {
    width: 100%;
    margin-right: 0px;
  }
  .home-banner {
    height: 480px;
    padding: var(--dl-space-space-twounits);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
@media(max-width: 479px) {
  .home-hero-image {
    height: 297px;
  }
  .home-section {
    height: 677px;
    margin-bottom: 0px;
  }
  .home-content1 {
    left: 361px;
    width: 405px;
    bottom: 41px;
    margin-left: 24px;
    background-color: rgba(61, 60, 60, 0.73);
  }
  .home-text {
    margin-top: var(--dl-space-space-halfunit);
  }
  .home-max-width2 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-image2 {
    width: 100%;
    height: 361px;
    padding-right: 0px;
    padding-bottom: 0px;
  }
  .home-hero-image1 {
    width: 100%;
    height: 374px;
  }
  .home-image3 {
    height: 0;
  }
  .home-banner {
    height: 231px;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
